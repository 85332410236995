import { Link, graphql } from "gatsby"
import React from "react"

interface Props {
  id: number
  title: string
  eventDate: Date
  publishedDate: Date
  location: string
  type: string
  internalLink: string
  ctaInternalLink?: string
  image: string
  eventDetails: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
  data?: any
}

const EventDetails = ({
  title,
  eventDate,
  publishedDate,
  location,
  type,
  internalLink,
  eventDetails,
  image,
  data,
}: Props) => {
  console.log(data)
  return (
    <section className="event-detailed bg-darkened">
      <div className="content-wrapper">
        <div className="breadcrumb">
          <Link to="/events">Events</Link>
          <span>&gt;</span>
          <Link to={internalLink}>{title}</Link>
        </div>
        <i className="d-block mb-2">
          Published: {publishedDate.toLocaleDateString()}
        </i>
        <img src={image} alt="" className="img-fluid" />

        <div className="main-body">
          <h1>{title}</h1>
          <div className="date-block mb-3">
            {type !== "News" && (
              <>
                <h4 className="bold">
                  Event date: {eventDate.toLocaleDateString()}
                </h4>
                <h4 className="bold">Location: {location}</h4>
              </>
            )}
          </div>
          {eventDetails}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  query($uid: String) {
    prismicEvent(uid: { eq: $uid }) {
      data {
        event_title {
          text
        }
        header_image {
          url
        }
        publish_date
        event_date
        event_category
        location
        event_body {
          html
        }
      }
    }
  }
`

export default EventDetails
