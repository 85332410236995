import { Link } from "gatsby"
import React from "react"

const flapmaxlogo = require("../images/faviconflapmax.png")
const fualaunch = require("../images/fualaunch.png")
const gcpbanner = require("../images/gcpbanner.jpg")
const foundher = require("../images/foundher.jpg")
const externship = require("../images/externship.jpg")
const inventorsprint = require("../images/inventorsprint.jpg")
const austinlogo = require("../images/austin.png")
const tennesseelogo = require("../images/tennessee.png")
const plugnplay = require("../images/plugnplay.png")
const sc21banner = require("../images/sc21banner.jpeg")
const sc21 = require("../images/sc21.png")

export const eventData = [
  {
    id: 5137136,
    title: "Flapmax University Alliance Launch",
    eventDate: new Date("03/01/2021"),
    publishedDate: new Date("03/01/2021"),
    type: "News",
    location: "",
    image: fualaunch,
    logo: flapmaxlogo,
    internalLink: "/event/fua-official-launch",
    eventDetails: (
      <div>
        <p>
          It's with great satisfaction that Flapmax announces our official
          launch of the Flapmax University Alliance program-- and with excellent
          timing as we dive into and celebrate Women's History Month.
        </p>
        <p>
          Our program seeks to combine people of all backgrounds and skill
          levels to collaborate and work on tech innovations for the future,
          with a specific focus of promoting diversity in STEM and tech.
        </p>
        <Link to="/join">Join the Program</Link>
      </div>
    ),
  },

  {
    id: 4214314,
    title: "FoundHER Career Fair with Plug and Play Tech Center",
    eventDate: new Date("03/24/2021"),
    publishedDate: new Date("03/09/2021"),
    type: "Career Fair",
    location: "Virtual",
    image: foundher,
    logo: plugnplay,
    internalLink: "/event/found-her-career-fair-plug-and-play",
    eventDetails: (
      <div>
        <p>
          The FoundHER initiative will be hosting their first ever remote career
          fair on March 24th to help close out Women's History Month with a
          bang. They are eager in seeking to connect women from their education
          partners with start-ups who are looking for talent-- including
          Flapmax!
        </p>
        <p>
          If you're looking to get your foot in the door and kick off your
          career or simply scout out companies of interest this event is for
          you. Make sure you register in advance and are ready to roll at
          08:30AM (PST) on March 24th! We'll see you there.
        </p>
        <a
          href="https://live.remo.co/e/startup-summer-internship-career/register"
          target="_blank"
        >
          View Official Registration Page
        </a>
      </div>
    ),
  },
  {
    id: 5117171,
    title: "Inventors Sprint with University of Texas at Austin",
    eventDate: new Date("06/05/2021"),
    publishedDate: new Date("03/09/2021"),
    type: "Science Sprint",
    location: "Virtual",
    image: inventorsprint,
    logo: austinlogo,
    internalLink: "/event/inventors-sprint-with-university-texas-at-austin",
    eventDetails: (
      <div>
        <p>
          University of Texas at Austin will be hosting an event under their
          popular Inventors Program in April. The Inventors Program takes
          real-world problems from industry and organizational partners and puts
          students to the test to try and solve them, building experience and
          entrepreneurial connections along the way.
        </p>
        <p>
          That’s a great way to try your hand in the STEM industry and see how
          you fare against the problems within it - Flapmax will provide the
          problem and feedback! By the end students leave with a hands on
          experience delving into potential career pathways.
        </p>
        <a href="https://cns.utexas.edu/" target="_blank">
          View their College of Natural Sciences Site
        </a>
      </div>
    ),
  },
  {
    id: 2315235,
    title: "Student Cluster Competition with Tennessee State University",
    eventDate: new Date("04/19/2021"),
    publishedDate: new Date("03/01/2021"),
    type: "Competition",
    location: "Virtual",
    image: gcpbanner,
    logo: tennesseelogo,
    internalLink: "/event/gcp-student-cluster-competition",
    eventDetails: (
      <div>
        <p>
          With much support from HPC-AI Advisory Council, Flapmax is proud to
          partner with Tennessee State University, a historically black college
          and university (HBCU), at the Winter Classic Cluster Competition.
        </p>
        <p>
          Along with faculty at TSU, Software engineers at Flapmax will mentor a
          team of TSU students as the team develops code and run HPC benchmarks
          for real world supercomputing applications. Other participating
          collaborators/sponsors include Dell and Arm.
        </p>
        <a
          href="https://www.winterclassicinvitational.com/winter-classic-invitational-applications-announced/"
          target="_blank"
        >
          View Official Event Page
        </a>
      </div>
    ),
  },
  {
    id: 1251516166,
    title: "ACM Student Research Competition at SC21",
    eventDate: new Date("11/16/2021"),
    publishedDate: new Date("08/13/2021"),
    type: "Competition",
    location: "St. Louis, Missouri",
    image: sc21banner,
    logo: sc21,
    internalLink: "/event/acm-student-research-competition",
    eventDetails: (
      <div>
        <p>
          The SC Conference hosts the ACM Student Research Competition (SRC)
          showcasing original research from undergraduate and graduate students.
          Students must be members of ACM at the time the poster is submitted.
          ACM Student membership is available for $19 per year. If you are a
          student who would like to showcase your work and compete for ACM SRC
          prizes, submit your poster to this track.
        </p>
        <p>
          Submissions are peer-reviewed by the SC ACM SRC Committee, which also
          selects a set of finalists based on poster content and presentation
          quality. These finalists go on to compete in the ACM SRC grand finals.
        </p>

        <h3>Deadlines</h3>

        <p>
          Submissions close: August 22, 2021
          <br />
          Notifications: September 10, 2021
        </p>

        <h3>Awards</h3>

        <p>
          The SC ACM SRC committee selects three graduate and three
          undergraduate finalists. The finalists of the SC ACM SRC will each
          receive a medal and prizes of $500, $300, and $200 for first, second,
          and third place in the undergraduate and graduate competition. The
          first placed undergraduate and graduate winners will go on to compete
          in the ACM SRC grand finals.
        </p>

        <h3>Travel Support</h3>

        <p>
          If your poster is accepted, the ACM SRC program will cover expenses up
          to $500 (e.g., transportation, accommodation, conference
          registration). Please review the ACM SRC website for reimbursement
          details.
        </p>

        <p>
          For more details and submission information:{" "}
          <a
            href="https://sc21.supercomputing.org/submit/poster-submissions/acm-student-research-competition-submissions/"
            target="_blank"
          >
            <b>Please visit their event page.</b>
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 5161327,
    title: "UT Austin Externship with Q&A",
    eventDate: new Date("01/13/2022"),
    publishedDate: new Date("11/01/2021"),
    type: "Externship",
    location: "Virtual",
    image: externship,
    logo: plugnplay,
    internalLink: "/event/ut-austin-externship-and-q-a",
    eventDetails: (
      <div>
        <p>
          Flapmax will be joining UT Austin for their externship event on
          January 13th, 2022 at 1:00PM-2:00PM CST. There will be time at the end
          of the event for a Q&A as well.
        </p>
        <p>
          Student registration will begin November 1st. We encourage any and all
          students studying Electrical, Biomedical, Computational, Environmental
          Engineering to register and join us.
        </p>
        <a
          href="https://cockrell.utexas.edu/student-life/career-services/externships"
          target="_blank"
        >
          View Official Registration Page
        </a>
      </div>
    ),
  },
]
